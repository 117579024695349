<template>
  <div>
    <div style="font-size: 50px; font-weight: bold; text-align: center">
      UD GLOBAL 后台管理系统
    </div>
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        value1:'',
        title:''
      }
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
      console.log(this)
    },
    methods: {},
  }
</script>

<style></style>
